/* General Styles */
body {
  font-family: 'Roboto', Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  color: #333;
  line-height: 1.6;
}

/* Header */
header {
  background-color: #4a90e2;
  color: white;
  padding: 1.5em 0;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

header h1 {
  margin: 0;
  font-size: 2rem;
}

header nav ul {
  list-style: none;
  padding: 0;
  margin: 1em 0 0;
  display: flex;
  justify-content: center;
  gap: 1.5em;
}

header nav ul li {
  margin: 0;
}

header nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
  transition: color 0.3s ease;
}

header nav ul li a:hover {
  color: #d1ecf1;
}

/* Footer */
footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 1em 0;
  margin-top: 2em;
}

footer p {
  margin: 0;
}